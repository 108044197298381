<script>
import { Bar } from "vue-chartjs";
import chartjsPluginAnnotation from "chartjs-plugin-annotation";

export default {
  extends: Bar,
  props: ["chartdata", "options"],
  mounted() {
    this.addPlugin([chartjsPluginAnnotation]);
    this.renderChart(this.chartdata, this.options);
  }
};
</script>

<style></style>
