var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Statistiken")]),_c('v-card',{attrs:{"loading":_vm.loading && !_vm.summaries}},[_c('v-tabs',{model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}},[_c('v-tab',{key:0},[_vm._v(" Woche ")]),_c('v-tab',{key:1},[_vm._v(" Monat ")]),_c('v-tab',{key:2},[_vm._v(" Jahr ")])],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-0"},[_c('div',{staticClass:"period-navigation"},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.selectedPeriod >=
                (_vm.period === 0 ? _vm.weeks.length : _vm.period === 1 ? _vm.months.length : _vm.years.length) - 1},on:{"click":function($event){_vm.selectedPeriod += 1}}},[_c('v-icon',[_vm._v("chevron_left")])],1),_c('v-select',{attrs:{"loading":_vm.loading,"hide-details":"","label":"Zeitraum","solo":"","item-text":"text","item-value":"id","flat":"","items":_vm.period === 0 ? _vm.weeks : _vm.period === 1 ? _vm.months : _vm.years},model:{value:(_vm.selectedPeriod),callback:function ($$v) {_vm.selectedPeriod=$$v},expression:"selectedPeriod"}}),_c('v-btn',{attrs:{"disabled":_vm.selectedPeriod === 0,"icon":""},on:{"click":function($event){_vm.selectedPeriod -= 1}}},[_c('v-icon',[_vm._v("chevron_right")])],1)],1),_c('div',{staticClass:"mb-5"},[_c('div',{style:({
              float: 'right',
              cursor: 'pointer',
              textDecoration: _vm.showEarnings ? null : 'line-through',
              textAlign: 'right',
            }),on:{"click":function($event){_vm.showEarnings = !_vm.showEarnings}}},[_vm._v(" Einnahmen: "+_vm._s(_vm.formatEarnings(_vm.totalEarnings, 0))+" €"),_c('br'),_c('small',[_vm._v(" Tagesdurschnitt: "+_vm._s(_vm.formatEarnings(_vm.dailyAverageEarnings, 0))+" € ")]),_c('br'),_c('small',{style:({
                color:
                  _vm.overearnings < 0 ? this.undertimeColor : this.overtimeColor,
              })},[_vm._v(" Über/Unter Soll: "+_vm._s(_vm.formatEarnings(_vm.overearnings, 0))+" € ")])]),_c('div',{style:({
              cursor: 'pointer',
              textDecoration: _vm.showDuration ? null : 'line-through',
            }),on:{"click":function($event){_vm.showDuration = !_vm.showDuration}}},[_vm._v(" Arbeitszeit: "+_vm._s(_vm.formatSeconds(_vm.totalDuration).substring(0, 5))+" Stunden"),_c('br'),_c('small',[_vm._v(" Tagesdurschnitt: "+_vm._s(_vm.formatSeconds(_vm.dailyAverageDuration).substring(0, 5))+" ")]),_c('br'),_c('small',{style:({
                color:
                  _vm.overtime < 0 ? this.undertimeColor : this.overtimeColor,
              })},[_vm._v(" Überstunden: "+_vm._s(_vm.formatSeconds(_vm.overtime))+" ")])])]),_c('BarChart',{key:_vm.barChartKey,attrs:{"chartdata":_vm.barChartdata,"options":_vm.chart_options}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }